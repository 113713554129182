<template>
  <div class="page_403">
    <div class="tt">
      <h2 class="title">403</h2>
      <p class="desc">很抱歉，你没有权限访问此页面。</p>
    </div>
    <img src="./403.png" alt="" class="img" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.page_403 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  .tt {
    .title {
      font-size: 120px;
      color: #fedca3;
    }
    .desc {
      font-size: 36px;
      font-weight: 500;
      color: #444444;
      line-height: 70px;
    }
  }
  .img {
    width: 720px;
    height: 370px;
  }
}
</style>
